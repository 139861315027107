.tealium {
  display: none !important;
}
$enable_cookie-img: false !default;

.customer-service-section {
  div {
    display: block !important;
  }
}

#bt_notification {
  padding: 0;
  border: 0;
  .bt-content {
    padding: 5px;
    z-index: 1000;
    font-size: 12px;
    line-height: 16px;
    color: $color-white;
    border: 1px solid $color-black;
    background: $color-dark-gray;
    transition: all 5s;
    -webkit-transition: all 5s; /* Safari */
    position: fixed;
    bottom: 10px;
    #{$rdirection}: 0;
    width: 100%;
    @include breakpoint($medium-up) {
      #{$rdirection}: 10px;
      width: 24.5em;
    }
    h6 {
      font-size: 15px;
      margin-bottom: 10px;
    }
    .btn {
      color: $color-white;
      font-weight: 500;
      letter-spacing: 1.92px;
      padding: 14px 35px;
      text-transform: uppercase;
      display: inline-block;
    }
    .accept-privacy {
      &.btn-wrap {
        display: none;
      }
    }
    .bt-pp-section {
      background: $color-black;
      padding: 10px;
      border: 1px solid $color-button-gray;
      .bt-content__sub-hdr {
        border: 0;
        font-size: 15px;
        margin-bottom: 10px;
      }
    }
    .bt-content__link {
      color: $color-white;
      font-weight: bold;
      border: 0;
      margin-top: 10px;
      width: 100%;
      height: auto;
      text-decoration: underline;
    }
    .bt-cookie-section {
      padding: 10px;
      font-family: $roboto-text;
      background: $color-white;
      color: $color-black;
      .bt-content__sub-hdr {
        border: 0;
        font-size: 15px;
        margin-bottom: 10px;
      }
    }
    .btn-wrap {
      text-align: center;
      background: $color-black;
      margin-top: 10px;
      border: 1px solid $color-white;
      .button {
        background: $color-black;
        border-color: $color-black;
        &:hover {
          background: $color-black;
          border-color: $color-black;
        }
      }
    }
    .bt-close-link {
      background: url('/media/images/cookies/close_x.png') 50% 50% no-repeat;
      position: absolute;
      top: 10px;
      #{$ldirection}: 92%;
      color: $color-white;
      width: 15px;
      height: 15px;
    }
  }
}
/* SETTINGS
 - make changes under the BRAND SPECIFICS section
---------------------------------------------- */
#bt_settings {
  .bt-content {
    /* DEFAULT CONTAINER STYLE */
    padding: 15px;
    background-color: $color-white;
    z-index: 1001;
    font-size: 12px;
    line-height: 16px;
    border: 1px solid $color-black;
    border-color: $color-blue-shade;
    border-width: 2px;
    border-radius: 6px;
    margin-bottom: 21px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    .bt-intro {
      h2 {
        text-transform: none;
        color: $color-blue-shade;
        font-size: 21px;
        font-weight: 500;
        margin-bottom: 21px;
      }
      .bt-intro-copy {
        font-size: 14px;
        p {
          margin-top: 14px;
          margin-bottom: 10px;
        }
      }
    }
    /* TOGGLE SLIDER */
    .slide {
      margin: 20px auto;
      position: relative;
      overflow: hidden;
      width: 246px;
      @include breakpoint($medium-up) {
        #{$rdirection}: 10px;
        width: 525px;
      }
    }
    @if $enable_cookie-img == true {
      .slide {
        width: 288px;
        @include breakpoint($medium-up) {
          width: 525px;
        }
      }
    }
    .slide-toggle {
      position: relative;
      overflow: hidden;
      background-image: url('/media/images/cookies/m_all-off.png');
      background-size: 246px 147px;
      width: 246px;
      @include breakpoint($medium-up) {
        background: transparent url('/media/images/cookies/all-off.png') 50% 100% no-repeat;
        background-size: contain;
        width: 100%;
      }
      li {
        cursor: pointer;
        float: $ldirection;
        list-style-type: none;
        width: 82px;
        height: 148px;
        @include breakpoint($medium-up) {
          width: 175px;
          height: 149px;
        }
        span {
          display: none;
          font-weight: bold;
          text-align: center;
          margin: 5px;
        }
      }
    }
    @if $enable_cookie-img == true {
      .slide-toggle {
        background: url('/media/images/cookies/m_all-off_v1.png') no-repeat;
        width: 288px;
        background-size: 288px 148px;
        @include breakpoint($medium-up) {
          background: url('/media/images/cookies/all-off_v1.png');
          width: 525px;
          background-size: contain;
        }
        li {
          width: 144px;
          @include breakpoint($medium-up) {
            width: 262px;
          }
        }
      }
    }
    .mask {
      position: absolute;
      overflow: hidden;
      top: 0;
      #{$ldirection}: 0;
      width: 0;
      border-#{$rdirection}: 1px solid $color-whisper-shade;
      transition: all 2s;
      -webkit-transition: all 2s; /* Safari */
      .slide-toggle {
        background-image: url('/media/images/cookies/m_all-on.png') no-repeat;
        margin: 0;
        @include breakpoint($medium-up) {
          background-image: url('/media/images/cookies/all-on.png');
        }
      }
      @if $enable_cookie-img == true {
        .slide-toggle {
          background: url('/media/images/cookies/m_all-on_v1.png') no-repeat;
          background-size: 288px 148px;
          margin: 0;
          @include breakpoint($medium-up) {
            background-image: url('/media/images/cookies/all-on_v1.png');
            background-size: contain;
          }
        }
      }
      .drag {
        position: absolute;
        bottom: 12px;
        width: 26px;
        height: 11px;
        background: transparent url('/media/images/cookies/icon_arrow.png') 50% 100% no-repeat;
        #{$rdirection}: 30px;
        @include breakpoint($medium-up) {
          #{$rdirection}: 70px;
        }
      }
      @if $enable_cookie-img == true {
        .drag {
          #{$rdirection}: 55px;
          @include breakpoint($medium-up) {
            #{$rdirection}: 100px;
          }
        }
      }
    }
    /* PRIVACY INFORMATION */
    .bt-privacy-info {
      display: none !important;
      overflow: hidden;
      &.selected {
        display: block !important;
        &[data-userprivacy='OPTED_IN'] {
          .bt-privacy-willnot {
            display: none !important;
          }
        }
      }
      h3 {
        padding-top: 1em;
        padding-#{$rdirection}: 1em;
        padding-bottom: 1em;
        padding-#{$ldirection}: 40px;
        background-position: 10px 50%;
        background-repeat: no-repeat;
        font-size: 15px;
        font-weight: 500;
      }
      .bt-privacy-will,
      .bt-privacy-willnot {
        font-size: 14px;
        font-weight: 400;
      }
      .bt-privacy-will {
        h3 {
          background-image: url('/media/images/cookies/icon_safe_lock.png');
        }
        ul {
          li {
            background-image: url('/media/images/cookies/icon_safe_thick.png');
          }
        }
      }
      .bt-privacy-willnot {
        h3 {
          background-image: url('/media/images/cookies/icon_unsafe_lock.png');
        }
        ul {
          li {
            background-image: url('/media/images/cookies/icon_unsafe_thick.png');
          }
        }
      }
      div {
        margin-#{$rdirection}: 2%;
        width: 100%;
        @include breakpoint($medium-up) {
          width: 48%;
          float: $ldirection;
        }
        ul {
          list-style-type: none;
          padding-#{$ldirection}: 0;
          margin: 1em;
          li {
            margin: 0.8em 0;
            padding-#{$ldirection}: 30px;
            background-position: 0 50%;
            background-repeat: no-repeat;
          }
        }
      }
    }
  }
  .bt-content-desktop {
    padding: 2em;
    height: 100%;
  }
  .bt-privacy-info h3 {
    background-color: $color-alice-blue;
  }
}
