$red: #cd3030;
$red-color: #c00;

.error {
  color: $red;
  &.hazmat {
    &.cart_item__error--hazmat {
      display: none;
    }
  }
}

.checkout {
  &__header {
    text-transform: none;
  }
  .offer-code-content {
    &__sign-in-link {
      display: none;
    }
  }
  &__sidebar {
    .order-summary-content {
      &__label--tax,
      &__value--tax {
        display: none;
      }
    }
  }
  fieldset {
    .form-item.address3 {
      display: none;
    }
  }
}

#password_reset {
  .password-reset-page {
    .password-reset {
      &__item {
        margin-top: 10px;
      }
    }
  }
}

.site-footer {
  .basic-noderef--email-signup {
    .site-email-signup {
      &__copy {
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 17px;
        text-transform: uppercase;
      }
    }
  }
  .icon--triman {
    @include breakpoint($medium-up) {
      vertical-align: middle;
    }
  }
}

@media (min-width: 768px) {
  .content-block2.content-block2__textbox-pos--right {
    .content-block2__inner-textbox {
      transform: translate(0, -50%);
      top: 60%;
      position: absolute;
      left: 10%;
      max-width: 550px;
    }
  }
}

label {
  &.field_label {
    &.error {
      color: $red-color;
    }
  }
}

.past-purchases-page__content {
  .past-purchases {
    .product-item__details {
      .product-item__add-to-cart {
        top: unset;
      }
    }
  }
}

.optanon-status-editable,
.optanon-status-on {
  input[type='checkbox'] {
    & ~ label {
      &:before {
        display: none;
      }
    }
  }
}

.optanon-alert-box-wrapper {
  .optanon-alert-box-bg {
    .optanon-alert-box-body {
      @include breakpoint($medium-up) {
        margin: 0 40px;
      }
    }
    .optanon-alert-box-button-container {
      @include breakpoint($medium-up) {
        position: relative;
        margin: 5px;
      }
    }
  }
}

.optanon-toggle-display,
.optanon-allow-all {
  &:hover {
    background: none;
  }
}

.cookie-settings-ot {
  @include swap_direction(padding, 0 2em 3em 0);
  margin: 0 auto;
  max-width: 1024px;
}

.search-form {
  input[type='submit'] {
    &.search-form {
      &__submit {
        height: 37px;
        line-height: 37;
        top: 0;
      }
    }
  }
}

@include breakpoint($med-small-down) {
  .basic-formatter {
    &__items {
      .mpp {
        &__category-name {
          h2 {
            span {
              font-weight: 500;
              font-style: normal;
              font-size: 13px;
            }
          }
        }
      }
    }
  }
}

.gnav-util {
  &__content {
    &--cart {
      @include breakpoint($medium-up) {
        top: 50px;
      }
    }
  }
}

.field-mobile-template {
  .content-block1--has-videoplayer {
    .content-block1 {
      &__play-icon {
        display: block;
      }
    }
  }
}

.content-block5 {
  &__box {
    /* stylelint-disable-next-line declaration-no-important */
    height: 100% !important;
  }
}
