.checkout {
  .checkout-panel {
    &__content {
      .city-state-zip {
        .with-field-lookup {
          padding-top: 12px;
        }
      }
      .payment-form {
        .payment-form--card-expiration {
          .expires-month,
          .expires-year {
            width: 49%;
          }
          .cvv {
            width: 100%;
          }
        }
      }
    }
    .cart-items {
      &__item--qty {
        #cart {
          .selectbox {
            min-width: 60px;
          }
        }
      }
    }
    .recommended-item {
      width: 48%;
      margin-right: 2%;
      &__cta {
        .button {
          white-space: pre-wrap;
        }
      }
    }
    &--shipping {
      .select-menu {
        select {
          width: 100%;
        }
      }
    }
  }
  &.samples-page {
    .product-list {
      .product {
        width: 100%;
        text-align: center;
        padding-bottom: 15px;
        padding-right: 0;
        &.deactivate {
          .button {
            background: $color-gray;
          }
        }
        .button {
          border-width: 0;
        }
      }
    }
    .samples-content {
      &__button-group--top {
        margin-bottom: 20px;
      }
      &__button-group--bottom {
        margin-top: 30px;
      }
      &__button {
        width: 100%;
        margin-bottom: 10px;
        .button {
          width: 100%;
        }
      }
    }
  }
  .checkout-buttons-content {
    &__container {
      .paypal-smart-button {
        display: inline-block;
        width: 100%;
        height: 50px;
        margin-bottom: 10px;
        padding: 1px;
        &.paypal-loading {
          position: relative;
          background: $color-white url(/media/images/global/ajax-loading.gif) no-repeat center center;
          text-align: center;
        }
      }
    }
  }
}
