/* Power reviews */
#pr-reviewdisplay {
  .p-w-r {
    .pr-rd-footer {
      .pr-helpful-yes {
        .pr-helpful-count {
          &:before {
            content: 'Oui -';
            visibility: visible;
          }
        }
      }
      .pr-helpful-no {
        .pr-helpful-count {
          &:before {
            content: 'non -';
            visibility: visible;
          }
        }
      }
    }
    .pr-review-snapshot {
      .pr-review-snapshot-header {
        .pr-snippet-write-review-link {
          width: 80%;
        }
      }
    }
  }
}
