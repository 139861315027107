/* Info popup styles for privacy policy */
.info-link-gdpr {
  cursor: pointer;
  margin-bottom: 10px;
  border: 0px;
  display: block;
  clear: both;
  text-decoration: underline;
  font-size: 15px;
  letter-spacing: 0.75px;
  &:hover {
    text-decoration: underline;
    border: 0px;
    color: inherit;
  }
}

.tools-pages {
  &.info-link-gdpr {
    width: 100px;
    margin: 0 auto;
    cursor: pointer;
  }
}

.info-icon-gdpr {
  @include swap_direction(padding, 1px 0 0 10px);
  cursor: pointer;
  background: $color-black;
  color: $color-white;
  border-radius: 20px;
  width: 25px;
  height: 25px;
  display: block;
  text-transform: lowercase;
  line-height: 20px;
  &:hover {
    color: $color-white;
  }
}

.info-message-txt-gdpr {
  display: none;
}

.info-msg-gdpr {
  position: absolute;
  #{$ldirection}: 0;
  top: 0;
  z-index: 10000;
  background-color: $color-white;
  padding: 20px;
  border: 1px solid $color-black;
  max-width: 510px;
  width: 90%;
  color: $color-black;
  &__arrow {
    content: ' ';
    height: 0;
    position: absolute;
    width: 0;
    #{$ldirection}: 0;
    border: 10px solid transparent;
    border-bottom-color: inherit;
    top: -10px;
    margin-top: -10px;
    &.top-right-arrow {
      #{$ldirection}: 478px;
    }
  }
  &__txt {
    position: relative;
  }
  &__close {
    position: absolute;
    top: -20px;
    #{$rdirection}: -20px;
    font-size: 15px;
    width: 20px;
    height: 20px;
    text-align: center;
    cursor: pointer;
  }
}

.site-footer {
  .info-link-gdpr {
    color: $color-white;
  }
}

.checkout-panel {
  .info-link-gdpr[message-in='checkout-newsletter-terms'] {
    float: #{$ldirection};
    margin-#{$ldirection}: 42%;
  }
}
.section-chatprivacypolicy {
  padding-top: 0 !important;
  .site-header,
  .site-footer {
    display: none;
  }
  .LPMcontainer {
    display: none !important;
  }
}
