.profile-page {
  .title-container {
    select {
      width: 100%;
    }
  }
  .profile-info {
    &__item {
      margin-bottom: 1em;
    }
  }
}

.address-overlay-shipping {
  .form_element {
    select,
    input[type='text'],
    input[type='tel'] {
      display: block;
      width: 100%;
    }
  }
}
